import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AnonymousGuard,
  AuthorizedGuard,
  InactivityGuard,
  AdminGuard,
} from './core/guards';
import { AcountVerifiedGuard } from './features/account-verification/guards/acount-verified.guard';
import { SuperAdminGuard } from './core/guards/super-admin.guard';
import { ContentManagerGuard } from './core/guards/content-manager .guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AnonymousGuard],
    children: [
      {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
      },
      {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.module').then(
          (m) => m.AuthModule),
        data: {
          title: 'Auth',
        },
      },
    ],
  },
  {
    path: '',
    canActivate: [AcountVerifiedGuard, AuthorizedGuard, InactivityGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'admin',
        canActivate: [AdminGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./features/admin/admin.module').then(
          (m) => m.AdminModule),
        data: {
          title: 'Admin',
        },
      },
      {
        path: 'super-admin',
        canActivate: [SuperAdminGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./features/super-admin/super-admin.module').then(
          (m) => m.SuperAdminModule),
        data: {
          title: 'Super Admin',
        },
      },
      {
        path: 'content-manager',
        canActivate: [ContentManagerGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./features/content-manager/content-manager.module').then(
          (m) => m.ContentManagerModule),
        data: {
          title: 'Content Manager',
        },
      },
      {
        path: 'view-document',
        loadChildren: () => import('./features/view-document/view-document.module').then(
          (m) => m.ViewDocumentModule),
        data: {
          title: 'View Document',
        },
      },
    ],
  },
  {
    path: 'error',
    loadChildren: () => import('./features/errors/errors.module')
      .then((m) => m.ErrorsModule),
  },
  // {
  //   path: '**',
  //   redirectTo: 'error/404',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
