import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FilterService, PrimeNGConfig } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { AppTitleService } from './core/services/app-title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
@UntilDestroy()
export class AppComponent implements OnInit {
  apiLoaded = false;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: AppTitleService,
    private readonly filterService: FilterService,
  ) {
  }

  ngOnInit(): void {
    if (!this.apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => ({ route: this.activatedRoute, event })),
      filter(({ route }) => route.outlet === 'primary'),
      map((value) => {
        while (value.route.firstChild) {
          value.route = value.route.firstChild;
        }
        while (!value.route.snapshot.data['title'] && value.route.parent) {
          value.route = value.route.parent;
        }
        return value;
      }),
      mergeMap(({ route, event }) => combineLatest([route.data, route.queryParams]).pipe(take(1), map((results) => ({
        data: results[0],
        queryParams: results[1],
        event,
      })))),
    ).subscribe((route: any) => {
      if (route.data.title) {
        let title = '';
        if (route.data.title instanceof Function) {
          title = route.data.title(route.data);
        } else {
          title = route.data.title;
        }
        this.titleService.set({ title, prefix: route.data.titleSuffix, browserTitle: route.data.browserTitle });
      }
    });

    this.createPrimeTableFilter();
  }

  createPrimeTableFilter() {
    this.filterService.register(
      'array-filter',
      (value: string[] | undefined, filter: string[] | undefined): boolean => {
        if (!filter || !filter.length) {
          return true;
        }
        if (!value) {
          return false;
        }
        return filter.some(
          (f) => value.some((v) => v === f) || (!f && !value.length));
      },
    );
  }
}
